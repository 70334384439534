<template>
  <div class="event-of-the-day" :class="classes" data-t="event-of-the-day">
    <NuxtI18nLink class="overlay-link" :to="eventUrl ?? '/'" />
    <img v-if="overlayImage" :src="overlayImage" class="overlay-image" alt="" />
    <div class="labels">
      <div v-if="isMainEvent" class="label">
        <span>{{ i18n.t('sportHomePage.eventOfTheDay') }}</span>
      </div>
      <StIcon
        v-if="isLive && size !== 's'"
        name="live"
        size="12"
        class="live-indicator"
        data-t="live-indicator"
      />
    </div>

    <div class="header">
      <div class="tournament" data-t="tournament">
        {{ tournamentFullName }}
      </div>
      <NuxtI18nLink class="competitors-link" :to="eventUrl ?? '/'">
        <div class="competitors" data-t="competitors">
          {{ competitors }}
        </div>
      </NuxtI18nLink>
    </div>

    <div class="content">
      <CompetitorLogo :competitor="event.home" />
      <div class="timing">
        <EventInfoTime
          :event="props.event"
          :is-mobile="props.isMobile"
          data-t="scheduled-time"
        />
        <div v-if="isLive" class="period-scores">
          <span class="score final">
            {{ scores.home }} : {{ scores.away }}</span
          >
          <span class="separator">
            <StIcon name="oval" :size="3" />
          </span>
          <span v-for="score in periodScores" :key="score.number" class="score">
            {{ score.home_score || 0 }} : {{ score.away_score || 0 }}
            <span class="separator">
              <StIcon name="oval" :size="3" />
            </span>
          </span>
        </div>
      </div>
      <CompetitorLogo :competitor="event.away" />
    </div>

    <div class="markets">
      <EventMarkets
        v-if="filterConfig || mainMarketConfig"
        :markets-filter="filterConfig || mainMarketConfig"
        :markets="activeMarkets"
        :button-props="{ isShownName: true, type: 'ghost', size: 'l' }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'
import { useDownloadAllMarkets } from '@st/nuxt-markets-store/composables/useDownloadAllMarkets'
import type { FilterConfig } from 'markets-store/marketsbook'
import { useMainMarket, useEventInfo } from '../../composables'
import CompetitorLogo from '../CompetitorLogo/CompetitorLogo.vue'
import EventMarkets from '../EventMarkets/EventMarkets.vue'
import EventInfoTime from '../EventInfoTime/EventInfoTime.vue'
import { getEventBackground } from '../../helpers/getEventBackground'
import soccerBg from './backgrounds/soccer.svg'
import basketballBg from './backgrounds/basketball.svg'
import tennisBg from './backgrounds/tennis.svg'
import hockeyBg from './backgrounds/hockey.svg'

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    event: TSportEvent
    filterConfig?: FilterConfig
    isMobile?: boolean
    size?: 's' | 'm'
    isMainEvent?: boolean
  }>(),
  {
    isMobile: false,
    size: 'm',
    isMainEvent: true,
  },
)

const { event } = toRefs(props)

const eventId = computed(() => event.value.id)
const eventUrl = computed(() =>
  props.isMobile ? event.value.mobileUrl : event.value.url,
)
/*
  нужно подгрузить все маркеты у главного события
  чтобы выводить аутками с названиями
*/
useDownloadAllMarkets(eventId)

const { mainMarketConfig, activeMarkets } = useMainMarket(event)
const { tournamentFullName, isLive, scores } = useEventInfo(event)

const competitors = computed(
  () => `${event.value.home?.name}  —  ${event.value.away?.name}`,
)

const periodScores = usePeriodScores(event)

const eventBackground = computed(
  () => `var(${getEventBackground(props.event.sportId).header})`,
)
const overlayImage = computed(
  () =>
    ({
      10: basketballBg,
      102: hockeyBg,
      149: soccerBg,
      167: tennisBg,
    })[props.event.sportId] ?? null,
)

const classes = computed(() => [{ mobile: props.isMobile }, props.size])
</script>

<style scoped>
.timing {
  display: flex;
  flex-basis: 60%;
  flex-direction: column;
  flex-shrink: 1;
  gap: var(--spacing-025);
  align-items: center;

  text-align: center;
}

.tournament {
  min-height: 12px;
  font: var(--desktop-text-xss-medium-caps);
  color: var(--text-secondary);
  text-transform: uppercase;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;

  height: var(--spacing-200);
  padding-inline: var(--spacing-075);

  font: var(--desktop-text-xxs-medium);

  background-color: var(--background-ghost-tertiary);
  border-radius: var(--spacing-100);
}

.event-of-the-day {
  position: relative;
  padding: var(--spacing-200) var(--spacing-150) var(--spacing-150)
    var(--spacing-150);
  background: v-bind(eventBackground);
  border-radius: var(--border-radius-150);

  &.mobile {
    .timing {
      @media (width < 460px) {
        flex-basis: 55%;
      }

      @media (width < 400px) {
        flex-basis: 50%;
      }
    }

    .tournament {
      overflow: hidden;

      max-width: 148px;
      margin: 0 auto;

      text-overflow: ellipsis;
      white-space: nowrap;

      @media (width >= 420px) {
        max-width: 218px;
      }
    }

    .label {
      max-width: 66px;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &.s {
    .tournament {
      overflow: hidden;

      max-width: 150px;
      margin: 0 auto;

      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.header {
  text-align: center;

  a {
    text-decoration: none;
  }
}

.overlay-link {
  position: absolute;
  z-index: 1;
  inset: 0;
  text-decoration: none;
}

.markets,
.competitors-link {
  position: relative;
  z-index: 2;
}

.content {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  min-height: 84px;
  padding-top: var(--spacing-050);
  padding-bottom: var(--spacing-200);
}

.competitors {
  overflow: hidden;

  margin-top: var(--spacing-025);

  font: var(--desktop-text-md-semibold);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: var(--text-link);
  }
}

.labels {
  position: absolute;
  top: var(--spacing-150);
  left: var(--spacing-150);

  display: flex;
  gap: var(--spacing-050);
  align-items: center;
}

.live-indicator {
  color: var(--text-link);
}

.overlay-image {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.period-scores {
  display: flex;
  align-items: center;

  margin-top: var(--spacing-050);

  font: var(--mobile-caption-2-medium);
  color: var(--text-secondary);
}

.separator {
  display: flex;
  align-items: center;

  margin-right: var(--spacing-025);
  margin-left: var(--spacing-025);
  padding: var(--spacing-050);
}

.score {
  display: flex;
  align-items: center;

  &.final {
    color: var(--text-primary);
  }

  &:last-child {
    .separator {
      display: none;
    }
  }
}
</style>
